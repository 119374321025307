const Action = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.83333 9.99935C5.83333 9.66971 5.73559 9.34748 5.55245 9.0734C5.36931 8.79932 5.10902 8.58569 4.80447 8.45955C4.49993 8.3334 4.16482 8.3004 3.84152 8.36471C3.51822 8.42901 3.22124 8.58775 2.98816 8.82084C2.75507 9.05392 2.59633 9.3509 2.53203 9.6742C2.46772 9.9975 2.50072 10.3326 2.62687 10.6372C2.75301 10.9417 2.96664 11.202 3.24072 11.3851C3.5148 11.5683 3.83703 11.666 4.16667 11.666C4.60869 11.666 5.03262 11.4904 5.34518 11.1779C5.65774 10.8653 5.83333 10.4414 5.83333 9.99935ZM14.1667 9.99935C14.1667 10.329 14.2644 10.6512 14.4476 10.9253C14.6307 11.1994 14.891 11.413 15.1955 11.5391C15.5001 11.6653 15.8352 11.6983 16.1585 11.634C16.4818 11.5697 16.7788 11.4109 17.0118 11.1779C17.2449 10.9448 17.4037 10.6478 17.468 10.3245C17.5323 10.0012 17.4993 9.66609 17.3731 9.36154C17.247 9.057 17.0334 8.7967 16.7593 8.61356C16.4852 8.43043 16.163 8.33268 15.8333 8.33268C15.3913 8.33268 14.9674 8.50828 14.6548 8.82084C14.3423 9.1334 14.1667 9.55732 14.1667 9.99935ZM8.33333 9.99935C8.33333 10.329 8.43108 10.6512 8.61422 10.9253C8.79735 11.1994 9.05765 11.413 9.3622 11.5391C9.66674 11.6653 10.0018 11.6983 10.3252 11.634C10.6485 11.5697 10.9454 11.4109 11.1785 11.1779C11.4116 10.9448 11.5703 10.6478 11.6346 10.3245C11.699 10.0012 11.6659 9.66609 11.5398 9.36154C11.4137 9.057 11.2 8.7967 10.926 8.61356C10.6519 8.43043 10.3296 8.33268 10 8.33268C9.55797 8.33268 9.13405 8.50828 8.82149 8.82084C8.50893 9.1334 8.33333 9.55732 8.33333 9.99935Z"
        fill="#667085"
      />
    </svg>
  );
};

export default Action;
