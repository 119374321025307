import { useEffect, useRef, useState } from "react";
import Action from "../../../../../marketing-cloud-main/src/common/assets/icons/Action";
import { DatePicker } from "antd";
import SegmentSideMenuBar from "../side-menu-bar";
import PaginationCommon from "../../../common/components/PaginationCommon";
import {
  addGAEventListener,
  getPageName,
} from "../../../../../marketing-cloud-container/src/ga-events";
const { RangePicker } = DatePicker;
import { MESSAGE } from "../../../../../marketing-cloud-main/src/common/constants/AccessControlMessage";
import { SegmentLabel } from "../../../common/assets/icons";

const SegmentLists = (props: any) => {
  const [showTypeMenu, setShowTypeMenu] = useState(false);
  const [checked, setChecked] = useState(null);
  const [showSegmentSideMenu, setShowSegmentSideMenu] = useState(false);
  const [segmentId, setSegmentId] = useState(null);
  const typeMenu = useRef(null);
  const [highlight, setHighlight] = useState(null);
  const {
    segmentList = [],
    setStartDate,
    setEndDate,
    setSegmentType,
    setPageNum,
    setFrom,
    setPageSize,
    pageSize,
    pageNum,
    filter,
    setFilter,
    ListLength,
    tenantContext,
  } = props;

  useEffect(() => {
    checkQueryString();
    addGAEventListener();
  }, [showTypeMenu]);

  const handleSelectType = (e) => {
    setSegmentType(e.target.value);
    setFilter(true);
    setChecked(e.target.value);
    setPageNum(0);
    setPageSize(10);
    setFrom(0);
    if (checked === e.target.value) {
      setChecked(null);
      setSegmentType(null);
      setFilter(false);
    }
  };

  const handleSideMenu = (item) => {
    setShowSegmentSideMenu(true);
    setSegmentId(item);
  };

  const handleDateRangeChange = (event) => {
    let sDate = new Date(event[0].$d);
    let eDate = new Date(event[1].$d);
    sDate.setHours(0, 0, 0, 0);
    eDate.setHours(0, 0, 0, 0);
    setStartDate(sDate.getTime().toString());
    setEndDate(eDate.getTime().toString());
  };

  const checkQueryString = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const h = urlParams.get("h");
    if (h) setHighlight(h);
  };

  return (
    <div
      onClick={() => {
        if (showTypeMenu) {
          setShowTypeMenu(false);
        }
        if (showSegmentSideMenu) {
          setShowSegmentSideMenu(false);
        }
      }}
      className="p-10 "
    >
      <div className="flex justify-between">
        <div className="flex justify-center items-center gap-3">
          <div className="flex gap-2">
            <div className="relative">
              <button
                className="  inline-flex items-center px-4 py-3 text-sm leading-4 font-semibold text-center text-gray-70 bg-gray-10 rounded-full hover:bg-gray-100 triggerGA"
                data-event-category={`${getPageName()}-list-page`}
                data-event-label={`${getPageName()}-list_type-filter_click`}
                data-event-action="filter_click"
                type="button"
                onClick={() => setShowTypeMenu(!showTypeMenu)}
              >
                Type{" "}
                <svg
                  className="w-4 h-4 ml-2"
                  aria-hidden="true"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </button>

              {/* Type Dropdown menu */}
              {showTypeMenu && (
                <div
                  ref={typeMenu}
                  className="absolute mt-2 z-10 bg-white rounded-lg shadow w-44"
                >
                  <ul className="p-2 text-sm text-gray-700">
                    <li>
                      <div
                        className="flex items-center p-3 rounded hover:bg-gray-100 triggerGA"
                        data-event-category={`${getPageName()}-list-page`}
                        data-event-label={`${getPageName()}-list_type-option_click`}
                        data-event-action="filter_click"
                        data-event-value="custom"
                      >
                        <input
                          type="checkbox"
                          checked={checked === "custom" ? true : false}
                          value="custom"
                          onChange={handleSelectType}
                          className="w-4 h-4 accent-blue-75 bg-gray-100 border-gray-300 rounded cursor-pointer"
                        />
                        <button
                          onClick={handleSelectType}
                          value="custom"
                          className="w-full text-start ml-3 text-sm font-medium text-gray-700 rounded cursor-pointer"
                        >
                          Custom
                        </button>
                      </div>
                    </li>
                    <li>
                      <div
                        className="flex items-center p-3 rounded hover:bg-gray-100 triggerGA"
                        data-event-category={`${getPageName()}-list-page`}
                        data-event-label={`${getPageName()}-list_type-option_click`}
                        data-event-action="filter_click"
                        data-event-value="recommended"
                      >
                        <input
                          checked={checked === "recommended" ? true : false}
                          type="checkbox"
                          value="recommended"
                          onChange={handleSelectType}
                          className="w-4 h-4 accent-blue-75 bg-gray-100 border-gray-300 rounded cursor-pointer"
                        />
                        <button
                          onClick={handleSelectType}
                          value="recommended"
                          className="w-full ml-3 text-sm font-medium text-gray-700 rounded cursor-pointer"
                        >
                          Recommended
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>

          <div>
            {/* <RangePicker
              className="outline-none border-none h-10 gap-3 py-3 px-5 rounded-[47px] bg-gray-10 text-gray-600 shadow-none focus:{outline-none border-none bg-gray-10 shadow-none text-gray-600} placeholder:{text-gray-600}"
              onChange={handleDateRangeChange}
              locale={locale}
              showNow={false}
              allowClear={false}
              cellRender={(current, info) => {
                if (info.type !== "date") return info.originNode;
                const style: React.CSSProperties = {};
                style.borderRadius = "50%";
                style.width = "30px";
                style.height = "30px";
                style.lineHeight = "30px";
                return (
                  <div className="ant-picker-cell-inner" style={style}>
                    {current.date()}
                  </div>
                );
              }}
            /> */}
          </div>
        </div>

        <div className="group">
          <button
            onClick={() => props.navigate("/audience/create-segment")}
            className={`text-white text-sm leading-4 py-3 gap-2.5 h-10 px-5 font-semibold border rounded-lg bg-blue-75  cursor-pointer triggerGA ${
              tenantContext.productList["audience_create_policy"] &&
              "hover:bg-green-75  hover:border-green-75"
            } relative`}
            data-event-category={`${getPageName()}-page`}
            data-event-label="create-audience_click"
            data-event-action="button_click"
            disabled={!tenantContext.productList["audience_create_policy"]}
          >
            Create audience
            {!tenantContext.productList["audience_create_policy"] && (
              <div className="absolute hidden group-hover:flex bg-black p-3 text-xs rounded-lg w-[300px] right-[142px] top-[-12px] z-20">
                {MESSAGE}
              </div>
            )}
          </button>
        </div>
      </div>

      <div className="mt-10">
        <table className="min-w-full">
          <thead>
            <tr>
              {["Name", "Description", "Type", "Created on", " "].map(
                (item, index) => (
                  <th
                    key={index}
                    className={` ${
                      index === 4 ? "w-16" : index === 0 ? "w-128" : ""
                    } py-3 pr-2 border-b leading-6 font-semibold border-gray-100 text-left  text-gray-700`}
                  >
                    {item}
                  </th>
                )
              )}
            </tr>
          </thead>

          {(filter || pageNum > 0) && ListLength === 0 ? null : (
            <tbody className="max-h-screen relative">
              {segmentList?.map((item, index) => (
                <tr
                  key={index}
                  className={`-text border-b border-gray-100 text-gray-70 cursor-pointer hover:bg-gray-25 ${
                    highlight === item.audience_id && "animate-blink-2"
                  }`}
                >
                  <td className="py-4">
                    <div className="flex items-center gap-2">
                      <div className="p-2 flex-shrink-0">
                        <img
                          src={SegmentLabel}
                          alt=""
                          loading="lazy"
                          className="w-10 h-10"
                        />
                      </div>
                      <div className="first-letter:capitalize leading-5 font-medium text-gray-700 min-w-0">
                        {item.audience_name}
                      </div>
                    </div>
                  </td>
                  <td className="py-7 leading-5  font-medium text-gray-700">
                    {item.audience_description}
                  </td>
                  <td className="py-7 leading-5  font-medium text-gray-700 capitalize">
                    {item.audience_type}
                  </td>
                  <td className="">
                    {item.created_at?.split(".")[0]?.replace("T", " ")}
                  </td>
                  <td
                    onClick={() => {
                      handleSideMenu(index);
                    }}
                    className="text-base relative leading-6"
                  >
                    <div className="font-extrabold text-xl text-gray-700 py-2 whitespace-no-wrap">
                      {segmentId === index && showSegmentSideMenu && (
                        <div className="absolute z-10 top-16 right-0">
                          <SegmentSideMenuBar
                            setShowSegmentSideMenu={setShowSegmentSideMenu}
                            navigate={props.navigate}
                            id={item.audience_id}
                            isEditable={item.request_query}
                            tenantContext={tenantContext}
                          />
                        </div>
                      )}
                      <Action />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
        {(filter || pageNum > 0) && ListLength === 0 && (
          <div className="w-full p-24">
            <div className="flex flex-col w-full bg-gray-25 text-gray-90 text-center items-center p-6 border border-gray-200 rounded-lg">
              <div className="mt-2">No Data to Show.</div>
            </div>
          </div>
        )}
      </div>

      {pageNum === 0 && ListLength === 0 ? null : (
        <div className="mt-10 flex gap-5 text-sm items-center">
          <PaginationCommon
            setPageNum={setPageNum}
            pageNum={pageNum}
            pageSize={pageSize}
            ListLength={ListLength}
            setFrom={setFrom}
            setPageSize={setPageSize}
            setFilter={setFilter}
            filter={filter}
          />
        </div>
      )}
    </div>
  );
};

export default SegmentLists;
