import { useEffect, useState } from "react";
import SegmentLists from "./segment-lists";
import { getSegments } from "../../common/apis/segments";
import * as Sentry from "@sentry/react";
import {
  addGAEventListener,
  getPageName,
  pageLoadEvent,
} from "../../../../marketing-cloud-container/src/ga-events";
import { MESSAGE } from "../../../../marketing-cloud-main/src/common/constants/AccessControlMessage";
import { SegmentsIcon } from "../../common/assets/icons";

const SegmentMain = ({
  navigate,
  dispatch,
  setShowCommonLoader,
  tenantContext,
}) => {
  const [segmentList, setSegmentList] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNum, setPageNum] = useState(0);
  const [from, setFrom] = useState(0);
  const [segmentType, setSegmentType] = useState(null);
  const [filter, setFilter] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    const params = { pageSize, startDate, endDate, from, segmentType };
    getSegmentsList(params);
    setFrom(Number(pageNum * pageSize));
    pageLoadEvent("audience_page");
    addGAEventListener();
  }, [pageNum, pageSize, startDate, endDate, segmentType, from]);

  const getSegmentsList = (params = {}) => {
    dispatch(setShowCommonLoader(true));
    try {
      getSegments(params)
        .then((res) => {
          const { error, data, response } = res;
          if (!error) {
            setSegmentList(data);
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
            setSegmentList([]);
          }
          dispatch(setShowCommonLoader(false));
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
          setSegmentList([]);
          dispatch(setShowCommonLoader(false));
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      console.log(err);
      setSegmentList([]);
      dispatch(setShowCommonLoader(false));
    }
  };

  return (
    <div>
      {!filter && pageNum === 0 && segmentList?.length === 0 ? (
        <div className="flex items-center justify-between w-[64rem] mx-auto mt-48">
          <div className="flex items-start shrink-0 gap-6 flex-col">
            <div className="flex flex-col items-start self-stretch gap-2">
              <div className="text-gray-800 font-semibold text-4xl leading-10">
                No audience created yet.
              </div>
              <div className="text-gray-600 w-96 font-normal text-sm leading-6">
                Start building your audience by clicking 'Create audience'.
              </div>
            </div>
            <div className="flex items-start gap-3">
              <div className="group">
                <button
                  onClick={() => navigate("/audience/create-segment")}
                  className={`flex items-center justify-center gap-2 py-3 px-5 h-10 rounded-md bg-blue-75 ${
                    tenantContext.productList["audience_create_policy"] &&
                    "hover:bg-green-75"
                  } relative`}
                  disabled={
                    !tenantContext.productList["audience_create_policy"]
                  }
                >
                  <div
                    className="text-sm leading-4 font-semibold text-white triggerGA"
                    data-event-category={`${getPageName()}-page`}
                    data-event-label="create-audience_click"
                    data-event-action="button_click"
                  >
                    Create audience
                  </div>
                  {!tenantContext.productList["audience_create_policy"] && (
                    <div className="absolute hidden group-hover:flex bg-black p-3 text-xs text-white rounded-lg w-[300px] right-[-306px] top-[-12px] z-20">
                      {MESSAGE}
                    </div>
                  )}
                </button>
              </div>
              <button className="flex items-center justify-center gap-2 py-3 px-5 h-10 rounded-md border border-gray-400 bg-white">
                <div className="text-sm leading-4 font-semibold text-gray-90">
                  Learn more
                </div>
              </button>
            </div>
          </div>
          <img src={SegmentsIcon} alt="" loading="lazy" />
        </div>
      ) : (
        <div>
          <SegmentLists
            navigate={navigate}
            segmentList={segmentList}
            setSegmentType={setSegmentType}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setPageNum={setPageNum}
            setFrom={setFrom}
            setFilter={setFilter}
            filter={filter}
            pageSize={pageSize}
            ListLength={segmentList?.length}
            pageNum={pageNum}
            setPageSize={setPageSize}
            tenantContext={tenantContext}
          />
        </div>
      )}
    </div>
  );
};

export default SegmentMain;
