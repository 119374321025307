import { useEffect, useRef, useState } from "react";
import {
  addGAEventListener,
  getPageName,
} from "../../../../../marketing-cloud-container/src/ga-events";
import { ChevronDown, LeftArrow, RightArrow } from "../../assets/icons";

const PaginationCommon = (props: any) => {
  const [openSizeBar, setOpenSizeBar] = useState(false);
  const {
    setPageNum,
    pageNum,
    ListLength,
    setPageSize,
    setFrom,
    pageSize,
    filter,
    setFilter,
    hidePageNums = false,
    hidePageSize = false,
    handleNext,
    handlePrev,
  } = props;
  const sizeBar = useRef(null);
  const handleSelectPage = (e) => {
    setPageNum(Number(e.target.value));
  };
  useEffect(() => {
    addGAEventListener();
  }, [openSizeBar]);

  const handleSetPageSize = (e) => {
    setPageSize(Number(e.target.value));
    setFrom(0);
    setPageNum(0);
    setOpenSizeBar(false);
  };

  return (
    <div className="flex justify-center items-center gap-10">
      {!hidePageSize && (
        <div className="flex gap-1 justify-center items-center">
          <div className="py-2.5 font-medium text-gray-800">Items per page</div>
          <div className="relative">
            <button
              disabled={(filter || pageNum > 0) && ListLength === 0}
              onClick={() => setOpenSizeBar(!openSizeBar)}
              className="flex text-sm px-2 py-3 gap-2 font-medium leading-5 justify-center items-center w-14 h-10 bg-white border border-gray-200 rounded triggerGA"
              data-event-category={`${getPageName()}-list-page`}
              data-event-label="items-per-page-dropdown_click"
              data-event-action="button_click"
            >
              {pageSize}
              <div className={openSizeBar ? "rotate-180" : "rotate-0"}>
                <img src={ChevronDown} alt="" />
              </div>
            </button>
            {openSizeBar && (
              <div
                ref={sizeBar}
                className="absolute bottom-10 text-gray-70 text-center w-14 mt-2 z-10 bg-white rounded shadow border border-gray-200"
              >
                <ul className="p-2 text-sm text-gray-700 z-10">
                  <li>
                    <button
                      value={5}
                      onClick={handleSetPageSize}
                      className="py-1 rounded w-full hover:bg-gray-100 text-sm font-medium text-gray-700 cursor-pointer triggerGA"
                      data-event-category={`${getPageName()}-list-page`}
                      data-event-label="items-per-page-dropdown_page-num_click"
                      data-event-action="list_item_click"
                      data-event-value="5"
                    >
                      5
                    </button>
                  </li>
                  <li>
                    <button
                      value={10}
                      onClick={handleSetPageSize}
                      className="py-1 rounded w-full hover:bg-gray-100 text-sm font-medium text-gray-700 cursor-pointer triggerGA"
                      data-event-category={`${getPageName()}-list-page`}
                      data-event-label="items-per-page-dropdown_page-num_click"
                      data-event-action="list_item_click"
                      data-event-value="10"
                    >
                      10
                    </button>
                  </li>
                  <li>
                    <button
                      value={15}
                      onClick={handleSetPageSize}
                      className="py-1 rounded w-full hover:bg-gray-100 text-sm font-medium text-gray-700 cursor-pointer triggerGA"
                      data-event-category={`${getPageName()}-list-page`}
                      data-event-label="items-per-page-dropdown_page-num_click"
                      data-event-action="list_item_click"
                      data-event-value="15"
                    >
                      15
                    </button>
                  </li>
                  <li>
                    <button
                      value={20}
                      onClick={handleSetPageSize}
                      className="py-1 rounded w-full hover:bg-gray-100 text-sm font-medium text-gray-700 cursor-pointer triggerGA"
                      data-event-category={`${getPageName()}-list-page`}
                      data-event-label="items-per-page-dropdown_page-num_click"
                      data-event-action="list_item_click"
                      data-event-value="20"
                    >
                      20
                    </button>
                  </li>
                  <li>
                    <button
                      value={25}
                      onClick={handleSetPageSize}
                      className="py-1 rounded w-full hover:bg-gray-100 text-sm font-medium text-gray-700 cursor-pointer triggerGA"
                      data-event-category={`${getPageName()}-list-page`}
                      data-event-label="items-per-page-dropdown_page-num_click"
                      data-event-action="list_item_click"
                      data-event-value="25"
                    >
                      25
                    </button>
                  </li>
                  <li>
                    <button
                      value={30}
                      onClick={handleSetPageSize}
                      className="py-1 rounded w-full hover:bg-gray-100 text-sm font-medium text-gray-700 cursor-pointer triggerGA"
                      data-event-category={`${getPageName()}-list-page`}
                      data-event-label="items-per-page-dropdown_page-num_click"
                      data-event-action="list_item_click"
                      data-event-value="30"
                    >
                      30
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      )}

      <div className="flex items-center">
        <button
          disabled={pageNum < 1}
          onClick={() => {
            if (handlePrev) {
              handlePrev();
            } else {
              setPageNum(pageNum - 1);
              if (filter) {
                setFilter(false);
              }
            }
          }}
          className="flex justify-center box-border items-center gap-4 border rounded-l-md px-4 py-2 triggerGA"
          data-event-category={`${getPageName()}-list-page`}
          data-event-label={`${getPageName()}-list-pagination-previous_click`}
          data-event-action="button_click"
          data-event-value="Previous"
        >
          <div className="pl-1">
            <img src={LeftArrow} alt="" />
          </div>
          <div className="text-sm font-semibold text-gray-800">Previous</div>
        </button>
        {!hidePageNums && (
          <div
            className="triggerGA"
            data-event-category={`${getPageName()}-list-page`}
            data-event-label={`${getPageName()}-list-pagination_click`}
            data-event-action="button_click"
          >
            <button
              disabled={ListLength === 0}
              value={pageNum}
              onClick={handleSelectPage}
              className="border px-4 py-2.5 bg-gray-100 font-semibold text-gray-700"
            >
              {pageNum + 1}
            </button>
            <button
              disabled={ListLength == 0}
              onClick={handleSelectPage}
              value={pageNum + 1}
              className="border px-4 py-2.5 font-semibold  text-gray-700"
            >
              {pageNum + 2}
            </button>
            <button
              disabled={ListLength == 0}
              onClick={handleSelectPage}
              value={pageNum + 2}
              className="border px-4 py-2.5 font-semibold  text-gray-700"
            >
              {pageNum + 3}
            </button>
            <button
              disabled={ListLength == 0}
              className="border px-4 py-2.5 font-semibold  text-gray-700"
            >
              ...
            </button>
            <button
              disabled={ListLength == 0}
              onClick={handleSelectPage}
              value={pageNum + 9}
              className="border px-4 py-2.5 font-semibold  text-gray-700"
            >
              {pageNum + 10}
            </button>
          </div>
        )}
        <button
          disabled={ListLength == 0}
          onClick={() => {
            handleNext ? handleNext() : setPageNum(pageNum + 1);
          }}
          className="flex box-border items-center justify-center gap-4 border rounded-r-md py-2 px-4 triggerGA"
          data-event-category={`${getPageName()}-list-page`}
          data-event-label={`${getPageName()}-list-pagination-next_click`}
          data-event-action="button_click"
          data-event-value="Next"
        >
          <div className="text-sm font-semibold text-gray-800">Next</div>
          <div>
            <img src={RightArrow} alt="" />
          </div>
        </button>
      </div>
    </div>
  );
};

export default PaginationCommon;
