import { audienceApi } from '../../../../marketing-cloud-main/src/common/services/common-api-service';
import * as Sentry from "@sentry/react";

//TODO: To update api

export const getSegments = (params: any) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const {
    pageSize = 10,
    from = 0,
    startDate,
    endDate,
    segmentType = '',
  } = params;
  let url = `/v1/audience?from=${from}&size=${pageSize}`;
  if (segmentType) {
    url = url + `&audience_type=${segmentType}`;
  }
  if (startDate) {
    url = url + `&startDate=${startDate}`;
  }
  if (endDate) {
    url = url + `&endDate=${endDate}`;
  }
  return audienceApi
    .get(url, { headers: { Authorization: `bearer ${loginAccessToken}` } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      (error.response) ? Sentry.captureMessage("Error", error.response) : Sentry.captureMessage("Error", error);
    });
};
